import React, { useRef } from "react";
import dateFormat from "dateformat";
import "./print.css";
import capitalizeWordLetter from "../lib/capitalizeFirstLetter";

const PrintVDO = ({ items, componentRef, deliveryOrder }) => {
  function formatPageNumber(currentPage, totalPages) {
    const formattedCurrentPage = String(currentPage).padStart(2, "0");
    const formattedTotalPages = String(totalPages).padStart(2, "0");

    return ` ${formattedCurrentPage}/${formattedTotalPages}`;
  }

  const totalItems = deliveryOrder?.product?.length || 0;
  let itemsPerPage = 20;
  if (deliveryOrder.site_address) {
    itemsPerPage = 17;
  }

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentPage = 1;
  const pageNumberDisplay = formatPageNumber(currentPage, totalPages);

  const now = new Date(deliveryOrder?.date ? deliveryOrder?.date : null);
  const formattedDate = dateFormat(now, "dd-mm-yyyy");

  function calculateTotalPriceAfterDiscount(isItemDiscount, item) {
    const initialTotal =
      Number(
        item?.category_name === "Ducting" ? item?.unit_price : item?.sell_price
      ) * Number(item?.new_quantity);

    if (isItemDiscount && item?.item_discount) {
      const discountPercentage = Number(item.item_discount);
      const discountAmount = (initialTotal * discountPercentage) / 100;
      const totalAfterPercentageDiscount = initialTotal - discountAmount;
      return totalAfterPercentageDiscount?.toFixed(2);
    } else {
      return initialTotal.toFixed(2);
    }
  }

  return (
    <>
      <div ref={componentRef} className="print-container h-full w-full">
        {Array(totalPages)
          .fill()
          .map((_, pageIndex) => {
            const startItemIndex = pageIndex * itemsPerPage;
            const endItemIndex = Math.min(
              startItemIndex + itemsPerPage,
              totalItems
            );
            const currentPageItems = items.slice(startItemIndex, endItemIndex);
            const pageNumberDisplay = formatPageNumber(
              pageIndex + 1,
              totalPages
            );

            return (
              <div key={pageIndex} className="page-content">
                <PageHeader
                  deliveryOrder={deliveryOrder}
                  formattedDate={formattedDate}
                  pageNumberDisplay={pageNumberDisplay}
                  capitalizeWordLetter={capitalizeWordLetter}
                />
                <div className="">
                  <div className="w-full">
                    <section className="page-body rounded-lg col-span-12 bg-white lg:col-span-8 w-full">
                      <table
                        className="text-sm text-left text-black"
                        style={{ width: "99%" }}
                      >
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-1 w-2 border border-gray-950 text-center"
                            >
                              S/N
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-1 border border-gray-950 w-32 text-center"
                            >
                              Item
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-1 border border-gray-950 text-center"
                            >
                              Size
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-1 border border-gray-950 text-center"
                            >
                              Unit
                            </th>
                            {/*<th
                              scope="col"
                              className="px-6 py-1 border border-gray-950 text-center"
                            > */}

                            <th
                              scope="col"
                              className="px-6 py-1 border border-black text-center"
                            >
                              Qty
                            </th>
                          </tr>
                        </thead>
                        <tbody className="border-gray-950">
                          {currentPageItems.map((item, index) => {
                            const isLastRowOnPage =
                              (index + 1) % itemsPerPage === 0;

                            return (
                              <tr
                                className={`bg-white border-r border-gray-950 ${
                                  isLastRowOnPage ? "last-row-on-page" : ""
                                }`}
                              >
                                <th className="font-normal text-center border-l border-r border-gray-950">
                                  {/* {item ? index + 1 : "\u00A0"} */}
                                  {pageIndex * itemsPerPage + index + 1}
                                </th>
                                <th
                                  scope="row"
                                  className=" px-2 whitespace-wrap border-r border-gray-950 "
                                  style={{ width: "250px" }}
                                >
                                  <div className="w-52  font-normal  text-dark text-xs flex flex-wrap">
                                    <div className="text-dark text-xs flex">
                                      {item?.category_name === "Ducting" && (
                                        <span className=" space-x-1 uppercase">
                                          <span className="ml-1">
                                            {item?.ducting_type?.product_type}
                                          </span>
                                          <span>
                                            {item?.ducting_type?.thickness}
                                          </span>
                                          <span>
                                            {item?.ducting_type?.brand_type}
                                          </span>

                                          <span>
                                            {item?.ducting_type?.joint_type}
                                          </span>
                                        </span>
                                      )}
                                      <span
                                        className={`${
                                          item?.category_name === "Ducting" &&
                                          "ml-1"
                                        }`}
                                      >
                                        {" "}
                                        {item?.name}
                                      </span>
                                    </div>
                                  </div>
                                </th>

                                <td className="px-1  border-r border-gray-950">
                                  <div className="text-xs flex item-center w-full">
                                    <h1 className="  break-keep">
                                      {/* {item?.description} */}
                                      {item?.category_name === "Ducting"
                                        ? item?.display_dimensions
                                        : item?.parent
                                        ? Object.values(
                                            item?.variant || {}
                                          ).join("/ ")
                                        : item?.description || "\u00A0"}
                                    </h1>
                                  </div>
                                </td>
                                <td className="px-1  border-r border-gray-950 text-center">
                                  {item?.unit == 0 ? null : item.unit}
                                </td>
                                <td className="px-1 border-r border-gray-950 text-center">
                                  {item?.new_quantity}
                                </td>
                              </tr>
                            );
                          })}
                          {pageIndex === totalPages - 1 && (
                            <>
                              <tr className="bg-white   border border-gray-950">
                                <th
                                  colSpan={4}
                                  className=" text-gray-950  border-r border-gray-950 text-right text-base font-medium"
                                >
                                  Total Qty:
                                </th>
                                {/* <th className=" text-gray-950 text-base font-medium text-center">
                                  {items?.length}
                                </th> */}
                                <th className="text-gray-950 text-base font-medium text-center">
                                  {items?.reduce(
                                    (acc, item) =>
                                      acc + parseInt(item.new_quantity),
                                    0
                                  )}
                                </th>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </section>
                  </div>
                </div>
                {pageIndex < totalPages && (
                  <RepeatingSection quotaionData={deliveryOrder} />
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

const RepeatingSection = (deliveryOrder) => (
  <section className="repeating-section">
    <div>
      <p className="font-bold text-xs">
        <strong>Terms & Conditions:</strong>
      </p>
      <p
        className="ml-6 text-xs bg-white w-full h-full"
        style={{ whiteSpace: "pre-wrap" }}
      >
        {/* {deliveryOrder?.terms_and_conditions} */}
        {deliveryOrder?.quotaionData?.terms_and_conditions}
      </p>
    </div>
    <div className="flex justify-between items-center px-4 mt-[-30px]">
      <div className="flex flex-col">
        <div className="flex justify-center">
          <img className="w-24" src="/signature.png" alt="" />
        </div>
        <h1 className="text-xs font-bold border-t-2 border-gray-950">
          Authorised Signature & Company Stamp
        </h1>
      </div>
      <div>
        <div className="flex justify-between space-x-4 items-center">
          <div className="text-xs font-bold">
            <div className="py-2 mt-1 flex justify-between items-center pr-5 space-x-10">
              <h1 className="w-60 font-bold mb-5">Accepted by:</h1>
              <div>
                <img className="w-24 mb-1" src="/uaf.jpg" alt="" />
                <img className="w-20" src="/biz.jpg" alt="" />
              </div>
            </div>
            <div className="w-60 border-t-2 border-gray-950 py-2 mt-1 flex justify-between pr-5 space-x-10">
              <h1>Authorised Signature & Company Stamp</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

//   company address two lining
function addressFormating(add) {
  let splited = add.split(",");
  const firstPart = splited
    .slice(0, splited.length - 2)
    .join(",")
    .trim();
  const secondPart = splited
    .slice(splited.length - 2)
    .join(",")
    .trim();
  var addValue = (
    <>
      <p>{firstPart}</p>
      <p>{secondPart}</p>
    </>
  );
  return addValue;
}

const PageHeader = ({
  deliveryOrder,
  formattedDate,
  pageNumberDisplay,
  capitalizeWordLetter,
}) => (
  <section className="page-header mb-0">
    <div>
      <div>
        <div className="flex justify-center items-center">
          <div className="text-center flex justify-center">
            <div className="w-full px-4 ">
              <h2 className="text-[22px] text-center text-green-600 font-bold leading-5">
                AMA M&E CONTRACTOR PTE LTD
              </h2>
              <div className="text-center mt-2">
                <dd className="ml-2 text-sm leading-3">
                  Company UEN/GST Registration No. 201700945E
                </dd>
              </div>
              <div className="text-center mt-2">
                <p className="ml-2 text-sm leading-5">
                  1 Tuas Bay Close #03-04, Liner, Singapore 636997
                </p>
              </div>
              <div className="text-center mt-2">
                <p className="ml-2 text-sm leading-3">
                  Tel: +65 6015 0254 HP: +65 8139 9772 E-mail:
                  amacontractors.sg@gmail.com
                </p>
              </div>
              <div>
                <h1 className="mt-2 text-base font-bold underline">
                  Delivery Order
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          <div className="pr-4">
            <h2 id="summary-heading" className="text-xl text-gray-900">
              {deliveryOrder?.company_name && (
                <div>
                  <h1 className="bg-gray-200 min-w-[280px] px-2 text-black text-sm font-bold">
                    Customer Address:
                  </h1>
                  <span className="text-base font-bold leading-snug">
                    {deliveryOrder?.company_name}
                  </span>
                  <p className="my-0 text-sm font-medium text-black whitespace-pre-wrap">
                    {deliveryOrder?.company_address
                      ? deliveryOrder.company_address
                      : ""}
                  </p>
                </div>
              )}
              {deliveryOrder?.attention && (
                <div className="flex items-center">
                  <h1 className="text-sm font-bold">Attention:</h1>
                  <div className="ml-2 text-black text-sm bg-white flex-grow">
                    {deliveryOrder?.attention}
                  </div>
                </div>
              )}

              {deliveryOrder?.site_address && (
                <div className="flex items-center">
                  <h1 className="text-sm font-bold">Project name & add:</h1>
                  <div className="ml-2 text-black text-sm bg-white flex-grow">
                    {deliveryOrder?.site_address?.address}
                  </div>
                </div>
              )}
              {deliveryOrder?.site_address && (
                <div className="flex items-center">
                  <h1 className="text-sm font-bold">Delivery Address:</h1>
                  <div className="ml-2 text-black text-sm bg-white flex-grow">
                    {deliveryOrder?.site_contact}
                  </div>
                </div>
              )}
            </h2>
          </div>
        </div>
        <div className="flex justify-end min-w-32 float-right">
          <div className="px-2 space-y-0">
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800 text-right">
                NO:
              </dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {deliveryOrder?.ref}
              </dd>
            </div>
            <div className="flex items-center justify-right">
              <dt className="text-sm font-bold text-gray-800">Date:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {formattedDate}
              </dd>
            </div>
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800 text-right">
                Order No:
              </dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {deliveryOrder?.order_number}
              </dd>
            </div>
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800 text-right">
                Order By:
              </dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {deliveryOrder?.order_by}
              </dd>
            </div>
            <div className="flex items-center justify-right">
              <dt className="text-sm font-bold text-gray-800">Terms:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {deliveryOrder?.terms}
              </dd>
            </div>
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800">Sales Person:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800 capitalize">
                {deliveryOrder?.sales_person
                  ? capitalizeWordLetter(deliveryOrder?.sales_person)
                  : ""}
              </dd>
            </div>
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800">
                {/* Delivery Contact:{" "} */}
              </dt>
              <dd className=" ml-2 text-sm  font-bold text-gray-800">
                {/* {deliveryOrder?.site_contact} */}
              </dd>
            </div>
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800">Page:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {pageNumberDisplay}
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PrintVDO;
