import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useHeader } from "../../lib/headers";
import { useParams } from "react-router-dom";
import Modal from "../Shared/Modal";
import UploadImages from "./UploadImages";
import Alert from "../Shared/Alert";
import Swal from "sweetalert2";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { BsPlusCircleDotted } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { FadeLoader } from "react-spinners";
import Barcode from "react-jsbarcode";
import BarcodeSection from "./BarcodeSection";
import ReactToPrint from "react-to-print";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }, { direction: "ltr" }],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link"],
    ["clean"],
  ],

  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const VariantSection = ({ setIsReload, isReload, setProducts, products }) => {
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [hideSelectedTab, setHideSelectedTab] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [product_variants, setProduct_variants] = useState([]);
  const [suggested_variants, setSuggested_variants] = useState([]);
  const [sellPrice, setSellPrice] = useState(null);
  const [purchasePrice, setPurchasePrice] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [submitedData, setSubmitedData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  const [isVariantFeatureImageLoading, setIsVariantFeatureImageLoading] =
    useState(false);
  const [isVariantLoading, setIsVariantLoading] = useState(false);
  const [imageURL, setImageURL] = useState([]);
  const [description, setDescription] = useState(null);
  const [varientFeatureImage, setVarientFeatureImage] = useState(null);
  const [unit, setUnit] = useState(null);

  const headers = useHeader();
  const { id } = useParams();
  const componentRef = useRef();
  useEffect(() => {
    setImageURL(selectedItem?.images);
    const featureImage = selectedItem?.images?.find((item) => item.is_featured);
    const images = selectedItem?.images?.find(
      (item) => item.is_featured === false
    );
    setVarientFeatureImage(featureImage);
  }, [selectedItem]);

  useEffect(() => {
    setProduct_variants(products?.product_variants);
    setSuggested_variants(products?.suggested_variants);
  }, []);

  const handleClose = () => {
    // setSelectedItem({});
    setShowModal(false);
  };

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const revealChapterBtn = (index, item) => {
    setSelectedItem(item);
    if (selectedChapter == index) {
      setHideSelectedTab(!hideSelectedTab);
    } else {
      setHideSelectedTab(false);
    }

    setSelectedChapter(index);
  };

  const handleVariantCheckbox = async (e, item, index) => {
    console.log("item checking", item);
    const { checked, value } = e?.target;
    if (checked) {
      const data = {
        parent: id,
        variant: item,
      };
      await axios
        .post(
          `${process.env.REACT_APP_ROOT_URL}/api/product_variant/`,
          data,
          headers
        )
        .then((response) => {
          setSuggested_variants(response?.data?.suggested_variants);
          setProduct_variants(response?.data?.product_variants);
          setProducts(response?.data);
          // Alert("Varient  Successfully Created", "success");
        });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const deleteProductVariant = async () => {
            await axios
              .delete(
                `${process.env.REACT_APP_ROOT_URL}/api/product_variant/${item?.id}`,
                headers
              )
              .then((response) => {
                setSuggested_variants(response?.data?.suggested_variants);
                setProduct_variants(response?.data?.product_variants);
                setProducts(response?.data);
                // Alert("Varient Deleted", "success");
              });
          };
          deleteProductVariant();
        }
      });
    }
  };

  const converter = (data) => {
    const modifiedData = {};
    for (const key in data) {
      if (key !== "exists" && key !== "id") {
        const value = data[key];
        modifiedData[key] =
          typeof value === "string" ? value.toUpperCase() : value;
      }
    }
    const output = Object.values(modifiedData).join(" - ");
    return output;
  };

  const handleSubmit = async (event, item) => {
    console.log("\\item from submit", item);
    event.preventDefault();
    setSubmitedData([...submitedData, item?.id]);
    try {
      const data = {
        // parent: id,
        // variant: item,
        sell_price: sellPrice,
        purchase_price: purchasePrice,
        quantity: quantity,
        description: description,
        unit: unit,
      };
      await axios
        .patch(
          `${process.env.REACT_APP_ROOT_URL}/api/product_variant/${item?.id}/`,
          data,
          headers
        )
        .then((response) => {
          setProduct_variants(response?.data?.product_variants);
          setProducts(response?.data);
          setSellPrice("");
          setPurchasePrice("");
          setQuantity("");
          setDescription("");
          Alert("Varient Price Successfully Update", "success");
        });
    } catch (error) {
      console.error("Error:", error);
      // Handle the error, show an error message, etc.
    }
  };

  const handleVarientFeatureImageUpload = async (e) => {
    setIsVariantFeatureImageLoading(true);
    const data = e.target.files[0];

    const formData = new FormData();
    formData.append("image", data);
    formData.append("product", selectedItem?.id);
    formData.append("is_featured", true);
    await axios
      .post(
        `${process.env.REACT_APP_ROOT_URL}/api/product_image/`,
        formData,
        headers
      )
      .then((res) => {
        const data = res?.data;
        // setIsReload(isReload + 1);
        setIsVariantFeatureImageLoading(false);
        const images = data?.images;
        setVarientFeatureImage(data);

        Alert("Varient Feature Image Uploaded", "success");
      })
      .catch((err) => {});
  };

  const handleVarientImageUpload = async (e) => {
    setIsVariantLoading(true);
    const data = e.target.files[0];

    const formData = new FormData();
    formData.append("image", data);
    formData.append("product", selectedItem?.id);
    formData.append("is_featured", false);
    await axios
      .post(
        `${process.env.REACT_APP_ROOT_URL}/api/product_image/`,
        formData,
        headers
      )
      .then((res) => {
        const data = res?.data;

        setIsReload(isReload + 1);
        setIsVariantLoading(false);
        const images = data?.images;
        setImageURL([...imageURL, data]);

        Alert("Varient Image Uploaded", "success");
      })
      .catch((err) => {});
  };

  const handleFeatureFileDelete = (e, item) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_ROOT_URL}/api/product_image/${item?.id}/`,
            headers
          )
          .then((response) => {
            setVarientFeatureImage(null);
            Alert("Your file has been deleted", "success");
          })
          .catch((error) => {});
      }
    });
  };
  const handleVarientFileDelete = (e, item, index) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_ROOT_URL}/api/product_image/${item?.id}/`,
            headers
          )
          .then((response) => {
            const newFile = [...imageURL];
            const rest = newFile.filter((file, i) => i !== index);
            setImageURL(rest);

            Alert("Your file has been deleted", "success");
          })
          .catch((error) => {});
      }
    });
  };
  return (
    <div>
      {/* suggested_variants start here  */}

      <div className="my-4 bg-[#F9FAFB] border rounded py-4 px-8  ">
        <h1 className=" text-lg font-bold  mb-4">Suggested Variants</h1>
        <div className="flex  items-center flex-wrap">
          {suggested_variants?.map((item, index) => (
            <div class="flex items-center mb-4 border rounded p-2 mx-2 ">
              <input
                checked={item?.exists ? true : false}
                onChange={(e) => handleVariantCheckbox(e, item, index)}
                id={index}
                type="checkbox"
                value=""
                class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded "
              />
              <label
                Varients
                for={index}
                class="ml-2 text-md font-medium text-gray-900 "
              >
                {converter(item)}
              </label>
            </div>
          ))}
        </div>
      </div>
      {/* suggested_variantsEnd here  */}

      <div className="py-1  ">
        <h1 className=" text-lg font-bold mb-2">Product Varients</h1>
        {product_variants?.map((item, index) => (
          <div
            key={index}
            className={` bg-[#F8F8F8] rounded  my-2     border `}
          >
            <div
              className={` flex justify-between cursor-pointer  py-4 md:px-6   `}
            >
              <div className=" flex flex-col   ">
                <h4 className="text-lg ">
                  {item?.id ? converter(item?.variant) : converter(item)}
                </h4>
                <div className=" grid grid-cols-5 gap-4 place-items-center ">
                  {products?.is_purchase && (
                    <div className=" mt-2 mb-2  w-full ">
                      <h1 className=" text-lg font-bold">Purchase Price</h1>

                      <div class=" w-full">
                        <input
                          // disabled={item?.id && true}
                          onWheel={(e) => e.target.blur()}
                          defaultValue={
                            item?.purchase_price && item?.purchase_price
                          }
                          onChange={(e) => setPurchasePrice(e.target.value)}
                          type="number"
                          id="purchase_price"
                          name="purchase_price"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                          required
                        />
                      </div>
                    </div>
                  )}
                  {products?.is_sell && (
                    <div className=" mt-2 mb-2  w-full">
                      <h1 className=" text-lg font-bold">Sell Price</h1>

                      <div class=" w-full">
                        <input
                          // disabled={item?.sell_price && true}
                          onWheel={(e) => e.target.blur()}
                          defaultValue={item?.id && item?.sell_price}
                          onChange={(e) => setSellPrice(e.target.value)}
                          type="number"
                          id="sell_price"
                          name="sell_price"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                          required
                        />
                      </div>
                    </div>
                  )}
                  <div className=" mt-2 mb-2  w-full">
                    <h1 className=" text-lg font-bold">Unit</h1>
                    <div class=" w-full">
                      <input
                        // disabled={item?.unit && true}
                        defaultValue={item?.id && item?.unit}
                        onChange={(e) => setUnit(e.target.value)}
                        type="text"
                        id="unit"
                        name="unit"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                        required
                      />
                    </div>
                  </div>
                  <div className=" mt-2 mb-2  w-full">
                    <h1 className=" text-lg font-bold">Available Quantity</h1>

                    <div class=" w-full">
                      <input
                        // disabled={item?.quantity && true}
                        onWheel={(e) => e.target.blur()}
                        defaultValue={item?.id && item?.quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        type="number"
                        id="quantity"
                        name="quantity"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                        required
                      />
                    </div>
                  </div>
                  {/* button Hide  */}
                  <button
                    onClick={(e) => handleSubmit(e, item)}
                    class={` ${
                      submitedData?.includes(item?.id) ? "hidden" : ""
                    } mt-2  py-2 px-3  text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300`}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <button
                onClick={() => revealChapterBtn(index, item)}
                className=" flex items-center relative"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-6 h-6  ${
                    selectedChapter === index &&
                    !hideSelectedTab &&
                    " rotate-180"
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </button>
            </div>

            {selectedChapter === index && !hideSelectedTab && (
              <div className=" pt-4 ">
                <div className=" mx-4 ">
                  <div className=" flex justify-center">
                    <div className="">
                      <BarcodeSection
                        name={
                          item?.id ? converter(item?.variant) : converter(item)
                        }
                        products={item}
                        ref={componentRef}
                      />
                      <ReactToPrint
                        trigger={() => (
                          <button className=" rounded border px-4 py-2 w-full mt-2 bg-gray-100">
                            Print
                          </button>
                        )}
                        content={() => componentRef.current}
                      />
                    </div>
                    {/* <Barcode
                      value={item?.barcode}
                      options={{ format: "code128", height: 50 }}
                      renderer="svg"
                    /> */}
                  </div>
                  {/* <div className=" grid grid-cols-12 gap-4 place-items-center ">
                    {products?.is_purchase && (
                      <div className=" mt-2 mb-2 col-span-3 w-full ">
                        <h1 className=" text-lg font-bold">Purchase Price</h1>

                        <div class=" w-full">
                          <input
                            // disabled={item?.id && true}
                            defaultValue={
                              item?.purchase_price && item?.purchase_price
                            }
                            onChange={(e) => setPurchasePrice(e.target.value)}
                            type="number"
                            id="purchase_price"
                            name="purchase_price"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                            required
                          />
                        </div>
                      </div>
                    )}

                    {products?.is_sell && (
                      <div className=" mt-2 mb-2 col-span-3 w-full">
                        <h1 className=" text-lg font-bold">Sell Price</h1>

                        <div class=" w-full">
                          <input
                            disabled={item?.sell_price && true}
                            defaultValue={item?.id && item?.sell_price}
                            onChange={(e) => setSellPrice(e.target.value)}
                            type="number"
                            id="sell_price"
                            name="sell_price"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                            required
                          />
                        </div>
                      </div>
                    )}
                    <div className=" mt-2 mb-2 col-span-3 w-full">
                      <h1 className=" text-lg font-bold">Unit</h1>
                      <div class=" w-full">
                        <input
                          disabled={item?.unit && true}
                          defaultValue={item?.id && item?.unit}
                          onChange={(e) => setUnit(e.target.value)}
                          type="text"
                          id="unit"
                          name="unit"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                          required
                        />
                      </div>
                    </div>

                    <div className=" mt-2 mb-2 col-span-3 w-full">
                      <h1 className=" text-lg font-bold">Available Quantity</h1>

                      <div class=" w-full">
                        <input
                          disabled={item?.quantity && true}
                          defaultValue={item?.id && item?.quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          type="number"
                          id="quantity"
                          name="quantity"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 "
                          required
                        />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className=" w-full h-32 mt-3">
                    <ReactQuill
                    
                      className="h-full"
                      modules={modules}
                      value={description}
                      onChange={setDescription}
                    />
                  </div> */}

                  {/* <button
                    onClick={(e) => handleSubmit(e, item)}
                    class="mt-16   py-2 px-3  text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                  >
                    Submit
                  </button> */}
                </div>

                {/* image Section start from here  */}
                <div class="mt-4 grid grid-cols-5 place-items-center gap-4">
                  {varientFeatureImage ? (
                    <>
                      <div class=" group w-full h-40 p-1 md:p-2 relative">
                        <img
                          alt="gallery"
                          class="ring-2 ring-green-400  block object-cover object-center w-full h-full rounded-lg"
                          src={varientFeatureImage?.image}
                        />
                        <p className="absolute left-[22%] top-[50%] block group-hover:bg-gray-300 group-hover:p-1 group-hover:rounded  text-gray-400">
                          Feature Image
                        </p>
                        <button
                          onClick={(e) =>
                            handleFeatureFileDelete(e, varientFeatureImage)
                          }
                          className="hidden group-hover:block hover:bg-white bg-red-400 hover:text-red-600 text-white absolute top-2 right-2 rounded-full  "
                        >
                          <RxCross2 size={30} className=" p-2" />
                        </button>
                      </div>
                    </>
                  ) : (
                    <div class="flex items-center justify-center h-40 ">
                      {isVariantFeatureImageLoading ? (
                        <div className=" flex justify-center items-center  w-full  h-40 p-1 md:p-2">
                          <FadeLoader
                            className=" w-full h-full "
                            color="#36d7b7"
                          />
                        </div>
                      ) : (
                        <label
                          for="dropzone-file"
                          class="flex flex-col items-center justify-center border-2 border-gray-300  rounded-lg cursor-pointer bg-gray-50 dark::hover:bg-bray-800 dark::bg-gray-700 hover:bg-gray-100 dark::border-gray-600 dark::hover:border-gray-500 dark::hover:bg-gray-600"
                        >
                          <>
                            <div class="flex flex-col items-center justify-center pt-5 pb-6 px-2">
                              <svg
                                class="w-8 h-8 mb-4 text-gray-500 dark::text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                              </svg>
                              <p class="mb-2 text-sm text-gray-500 dark::text-gray-400">
                                <span class="font-semibold">Upload </span>{" "}
                                Feature image
                              </p>
                              <p class="text-xs text-gray-500 dark::text-gray-400">
                                {" "}
                                PNG or JPG{" "}
                              </p>
                            </div>
                            <input
                              onChange={(e) =>
                                handleVarientFeatureImageUpload(e)
                              }
                              id="dropzone-file"
                              type="file"
                              class="hidden"
                            />
                          </>
                        </label>
                      )}
                    </div>
                  )}

                  {imageURL?.map((item, index) => (
                    <>
                      {!item?.is_featured && (
                        <div class=" group w-full  h-40 p-1 md:p-2 relative">
                          <img
                            alt="gallery"
                            class="block object-cover object-center w-full h-full rounded-lg"
                            src={item?.image}
                          />
                          <button
                            onClick={(e) =>
                              handleVarientFileDelete(e, item, index)
                            }
                            className="hidden group-hover:block hover:bg-white bg-red-400 hover:text-red-600 text-white absolute top-2 right-2 rounded-full  "
                          >
                            <RxCross2 size={30} className=" p-2" />
                          </button>
                        </div>
                      )}
                    </>
                  ))}

                  <div class="flex items-center justify-center  h-40">
                    {isVariantLoading ? (
                      <div className=" flex justify-center items-center  w-full  h-40 p-1 md:p-2">
                        <FadeLoader
                          className=" w-full h-full "
                          color="#36d7b7"
                        />
                      </div>
                    ) : (
                      <label
                        for="dropzone-file"
                        class="flex flex-col items-center justify-center border-2 border-red-300  rounded-lg cursor-pointer bg-gray-50   "
                      >
                        <>
                          <div class="flex flex-col items-center justify-center pt-5 pb-6 px-2">
                            <BsPlusCircleDotted size={30} />
                            <p class="mb-2 text-sm text-gray-500 dark::text-gray-400 mt-2">
                              <span class="font-semibold">Upload </span> Variant
                              image
                            </p>
                            <p class="text-xs text-gray-500 dark::text-gray-400">
                              {" "}
                              PNG or JPG{" "}
                            </p>
                          </div>
                          <input
                            onChange={(e) => handleVarientImageUpload(e)}
                            id="dropzone-file"
                            type="file"
                            class="hidden"
                          />
                        </>
                      </label>
                    )}
                  </div>
                </div>
                {/* image Section end   */}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VariantSection;
